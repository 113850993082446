;(function(){
    var forms = {
        'modal' : {
            selector: $("#modal-form"),
            fields: [
                {
                    'input'    : $('#modal-name'),
                    'type'     : 'string',
                    'required' : true
                },
                {
                    'input'    : $('#modal-phone'),
                    'type'     : 'phone',
                    'required' : true
                },
                {
                    'input'    : $('#modal-formname'),
                    'type'     : 'string',
                    'required' : true
                }
            ]
        },
        'contact' : {
            selector: $("#contact-form"),
            fields: [
                {
                    'input'    : $('#contact-name'),
                    'type'     : 'string',
                    'required' : true
                },
                {
                    'input'    : $('#contact-email'),
                    'type'     : 'email',
                    'required' : true
                },
                {
                    'input'    : $('#contact-phone'),
                    'type'     : 'phone',
                    'required' : true
                },
                {
                    'input'    : $('#contact-message'),
                    'type'     : 'string',
                    'required' : true
                }
            ]
        },
        //'callback' : {
        //    selector: $("#callback-form"),
        //    fields: [
        //        {
        //            'input'    : $('#callback-name'),
        //            'type'     : 'string',
        //            'required' : true
        //        },
        //        {
        //            'input'    : $('#callback-phone'),
        //            'type'     : 'phone',
        //            'required' : true
        //        }
        //    ]
        //},
        //'service' : {
        //    selector: $("#service-form"),
        //    fields: [
        //        {
        //            'input'    : $('#service-name'),
        //            'type'     : 'string',
        //            'required' : true
        //        },
        //        {
        //            'input'    : $('#service-phone'),
        //            'type'     : 'phone',
        //            'required' : true
        //        },
        //        {
        //            'input'    : $('#service-service'),
        //            'type'     : 'string',
        //            'required' : true
        //        }
        //    ]
        //}
    };

    $(".input__phone").mask("+7 (999) 999-99-99");

    for(var form in forms) if(forms.hasOwnProperty(form)){
        (function(form_data){
            var selector = form_data.selector,
                fields = form_data.fields,
                target = form_data.target;
            selector.on('submit', function(e){
                e.preventDefault();
                if(validator.validate(fields)){
                    console.log("sad");
                    var $this = $(this),
                        data,
                        req = $this.serializeArray(),
                        processData = true,
                        contentType = true;
                    if(form_data['files'] && form_data['files']['length']){
                        data = new FormData();
                        processData = false;
                        contentType = false;
                        for(var i in req){
                            data.append(req[i]['name'], req[i]['value']);
                        }
                        $.each(form_data['files'], function(a,b){data.append(a,b)});

                        $.ajax({
                            url: 'sendForm.php',
                            type: 'POST',
                            data: data,
                            cache: false,
                            dataType: 'json',
                            processData: false,
                            contentType: false,
                            success: function(data, textStatus, jqXHR) {
                                successForm($this, data, target);
                            }
                        })

                    }else{
                        $.post('sendForm.php', req, function(data){
                            successForm($this, data, target);
                        })

                    }
                }
                return false;
            });
        })(forms[form]);
    }

    function successForm($form, data, target){
        if (data['message']) {
            $form.html('<h3 class="text-center">' + data['message'] + '</h3>');
        }
    }
})();